import * as React from "react";
import "./AdminDrawer.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Link, NavLink, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
// import logo from "../../../images/logo.png";
// import { Container } from "@mui/material";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

// All Icones used in this component
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { GiWallet } from "react-icons/gi";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { makeStyles } from "@mui/styles";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

const useStyles = makeStyles({
  scroller: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "white",
    },
  },
});

let activeStyle = {
  textDecoration: "none",
  color: "white",
  fontSize: "18px",
  fontFamily: "Poppins",
  fontWeight: "bold",
};

let activeClassName = {
  textDecoration: "none",
  color: "rgba(255, 255, 255, 0.7)",
  fontSize: "14px",
  fontFamily: "Poppins",
};
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window, children, setopenModal } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const loc = useLocation();
  // console.log(loc, "location")
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const classes = useStyles();

  const drawer = (
    <Box py={1} sx={{ border: "0px" }} pr={0.4} className={classes.scroller}>
      <Box
        backgroundColor="#29C4F4"
        borderRadius="24px"
        sx={{ boxShadow: "2px 2px 2px lightgrey" }}
      >
        {/* <Container> */}
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box
            pt={4}
            pb={7}
            textAlign="center"
            fontSize="48px"
            fontWeight="400"
            color="white"
            fontFamily="Baloo Bhai"
          >
            Menu
          </Box>
        </Link>
        <List>
          <NavLink
            to="/distribute"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button>
              <DashboardIcon
                sx={{
                  color:
                    loc.pathname === "/distribute/next" ||
                    loc.pathname === "/distribute" ||
                    loc.pathname === "/"
                      ? "white"
                      : "rgba(255, 255, 255, 0.7)",
                }}
              />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight:
                      loc.pathname === "/distribute/next" ||
                      loc.pathname === "/" ||
                      loc.pathname === "/distribute"
                        ? "800"
                        : "400",
                    color:
                      loc.pathname === "/distribute/next" ||
                      loc.pathname === "/" ||
                      loc.pathname === "/distribute"
                        ? "white"
                        : "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  Distribute
                </span>
              </ListItemText>
            </ListItem>
          </NavLink>

          <NavLink
            to="/loadfunds"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button sx={{ marginTop: "10%" }}>
              <BarChartIcon />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: loc.pathname === "/loadfunds" ? "800" : "400",
                    // fontWeight: weight == 2 ? '800' : '400',
                    // color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  Load Funds
                </span>
              </ListItemText>
            </ListItem>
          </NavLink>

          <NavLink
            to="/users"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button sx={{ marginTop: "10%" }}>
              <GroupOutlinedIcon />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: loc.pathname === "/users" ? "800" : "400",
                    // fontWeight: weight == 3 ? '800' : '400',
                  }}
                >
                  Users
                </span>
              </ListItemText>
            </ListItem>
          </NavLink>
          {/* 
          <NavLink
            to="/updateUserInfo"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button sx={{ marginTop: "10%" }}>
              <GroupOutlinedIcon />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: loc.pathname === "/users" ? "800" : "400",
                    // fontWeight: weight == 3 ? '800' : '400',
                  }}
                >
                  Update User Record
                </span>
              </ListItemText>
            </ListItem>
          </NavLink> */}

          <NavLink
            to="/wallet"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button sx={{ marginTop: "10%" }}>
              <GiWallet style={{ fontSize: "22px" }} />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: loc.pathname === "/wallet" ? "800" : "400",
                    // fontWeight: weight == 4 ? '800' : '400',
                  }}
                >
                  Wallets
                </span>
              </ListItemText>
            </ListItem>
          </NavLink>

          <NavLink
            to="/apis"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button sx={{ marginTop: "10%" }}>
              <SettingsIcon />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: loc.pathname === "/apis" ? "800" : "400",
                    // fontWeight: weight == 5 ? '800' : '400',
                  }}
                >
                  APIs
                </span>
              </ListItemText>
            </ListItem>
          </NavLink>

          <NavLink
            to="/login"
            style={({ isActive }) => (isActive ? activeStyle : activeClassName)}
          >
            <ListItem button sx={{ marginTop: "68%", marginBottom: "15px" }}>
              <LogoutIcon />
              <ListItemText sx={{ paddingLeft: "8%" }}>
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Logout
                </span>
              </ListItemText>
            </ListItem>
          </NavLink>
        </List>
        {/* <Toolbar /> */}
        {/* </Container> */}
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        sx={{
          background: "#FFFFFF",
          boxShadow: "none",
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: {
              xs: "space-between",
              // sm: 'flex-end'
            },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" }, color: "black" }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            color="black"
            sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}
          ></Box>

          <Box display="flex" alignItems="center">
            {isConnected ? (
              <Button
                onClick={() => disconnect()}
                sx={{
                  background: "#29C4F4",
                  color: "white",
                  borderRadius: "10px",
                  width: "170px",
                  height: "50px",
                  fontSize: { md: "20px", xs: "14px" },
                  textTransform: "capitalize",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  "&:hover": {
                    background: "#29C4F4a1",
                  },
                }}
              >
                {address.slice(0, 4) + "..." + address.slice(-4)}
              </Button>
            ) : (
              <Button
                onClick={() => setopenModal(true)}
                sx={{
                  background: "#29C4F4",
                  color: "white",
                  borderRadius: "10px",
                  width: "170px",
                  height: "50px",
                  fontSize: { md: "20px", xs: "14px" },
                  textTransform: "capitalize",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  "&:hover": {
                    background: "#29C4F4a1",
                  },
                }}
              >
                connect
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)`, md: "100%" },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
ResponsiveDrawer.propTypes = { window: PropTypes.func };
export default ResponsiveDrawer;
