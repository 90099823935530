import React from "react";
import { Box, Container } from "@mui/material";

function Main({ address, Admin }) {
  return (
    <Box mt={30}>
      <Container>
        <Box display="flex" justifyContent="center">
          <Box
            width={{ xs: "90%", sm: "60%" }}
            py={3}
            backgroundColor="#F5F5F7"
            sx={{
              borderRadius: "15px",
            }}
            textAlign="center"
          >
            {address === Admin
              ? "Connected to Admin wallet!"
              : "Please connect to Admin wallet!"}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Main;
