import { Box, Container, Button, useMediaQuery } from "@mui/material";
import bg from "../../images/loginpage.svg";
import { useForm } from "react-hook-form";

export default () => {
  const matches = useMediaQuery("(min-width : 600px)");
  const matches1 = useMediaQuery("(min-width : 400px)");
  let {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const formSubmission = (data) => {
    console.log(data);
  };
  return (
    <>
      <Box pb={10} backgroundColor="#29C4F4">
        <Container maxWidth="xs">
          <Box textAlign="center" pt={7}>
            <img src="/images/seaLogo.png" width="25%" />
          </Box>
          <Box mt={7} textAlign="center">
            <form onSubmit={handleSubmit(formSubmission)}>
              <Box>
                <Box
                  pb={1}
                  textAlign="left"
                  pl={matches ? 5 : matches1 ? 12 : 7}
                  sx={{
                    fontSize: { md: "24px", xs: "16px" },
                    fontFamily: "Nunito",
                  }}
                >
                  E-mail
                </Box>
                <input
                  style={{
                    // margin: "5px",
                    background: "primary.main",
                    borderRadius: "30px",
                    color: "text.main",
                    height: "50px",
                    width: matches ? "350px" : "250px",
                    border: "none",
                    paddingLeft: "15px",
                  }}
                  type="email"
                  {...register("email", { required: true })}
                />

                <br />

                <Box
                  py={1}
                  textAlign="left"
                  pl={matches ? 5 : matches1 ? 12 : 7}
                  sx={{
                    fontSize: { md: "24px", xs: "16px" },
                    fontFamily: "Nunito",
                  }}
                >
                  Password
                </Box>
                <input
                  style={{
                    // margin: "5px",
                    background: "primary.main",
                    borderRadius: "30px",
                    color: "text.main",
                    height: "50px",
                    width: matches ? "350px" : "250px",
                    border: "none",
                    paddingLeft: "15px",
                  }}
                  type="password"
                  {...register("password", { required: true })}
                />
                <br />

                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "#113E64",
                    mt: 5,
                    // width: '350px',
                    padding: matches
                      ? "15px 155px 15px 155px"
                      : "15px 100px 15px 100px",
                    borderRadius: "30px",
                    "&:hover": {
                      background: "#113E64a1",
                    },
                  }}
                >
                  Login
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
      <Box
        pb={30}
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundColor: "#29C4F4",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Box>
    </>
  );
};
