import { Box, Container, Button, useMediaQuery } from "@mui/material";
import { useState, useContext } from "react";
import { MLMContract } from "../../ConnectivityAss/hooks";
import { AppContext, url, gasEstimationForAll } from "../../utils";
import { ToastNotify } from "../../ToastNotify";
import Loading from "../../loading";
import axios from "axios";
import { useAccount, useSigner } from "wagmi";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const matches = useMediaQuery("(min-width : 630px)");
  const [user, setUser] = useState();
  const [addUserAddr, setAddUserAddr] = useState();
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const [deleteUser, setDeleteUser] = useState();
  const [loading, setloading] = useState(false);
  // const { signer, account } = useContext(AppContext);
  const mlmContract = MLMContract(signer);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const HandleAdd = async () => {
    try {
      if (!user) {
        setAlertState({
          open: true,
          message: `Please check user name`,
          severity: "error",
        });
      } else if (!addUserAddr) {
        setAlertState({
          open: true,
          message: `Please check user address`,
          severity: "error",
        });
      } else {
        setloading(true);
        let data = [user, addUserAddr];
        let fn = mlmContract.estimateGas.addRegisterData;
        const tx = await mlmContract.addRegisterData(...data, {
          gasLimit: gasEstimationForAll(address, fn, data),
        });
        await tx.wait();
        let inputs = {
          user,
          addUserAddr,
        };
        let res = await axios.post("/addUser", inputs);
        setAlertState({
          open: true,
          message: `User added successfully.`,
          severity: "success",
        });
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  const HandleDelete = async () => {
    try {
      if (!deleteUser) {
        setAlertState({
          open: true,
          message: `Please add user address`,
          severity: "error",
        });
      } else {
        setloading(true);
        let data = [deleteUser];
        let fn = mlmContract.estimateGas.DeletRegisterData;
        const tx = await mlmContract.DeletRegisterData(...data, {
          gasLimit: gasEstimationForAll(address, fn, data),
        });
        await tx.wait();

        let res = await axios.post("/deleteUser", { deleteUser });

        setAlertState({
          open: true,
          message: `User deleted successfully.`,
          severity: "success",
        });
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  return (
    <Box>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container>
        <Box
          mt={7}
          sx={{
            color: "text.main",
            fontFamily: "Inter",
            fontSize: { md: "25px", xs: "18px" },
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Manage Backend Users
        </Box>
        <Box
          mt={3}
          p={1.5}
          backgroundColor="#F5F5F7"
          sx={{
            width: matches ? "550px" : "300px",
            borderRadius: "15px",
          }}
        >
          <Box
            mt={3}
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "32px", xs: "20px" },
              fontWeight: "bold",
            }}
          >
            Add User
          </Box>
        </Box>

        <input
          onChange={(e) => setUser(e.target.value)}
          value={user}
          type="text"
          placeholder="Enter user name"
          style={{
            width: matches ? "60%" : "100%",
            background: "white",
            color: "black",
            boxShadow: "2px 2px grey",
            borderRadius: "5px",
            height: "40px",
            marginTop: "35px",
          }}
        />
        <input
          onChange={(e) => setAddUserAddr(e.target.value)}
          value={addUserAddr}
          type="text"
          placeholder="Enter wallet address"
          style={{
            width: matches ? "60%" : "100%",
            background: "white",
            color: "black",
            boxShadow: "2px 2px grey",
            borderRadius: "5px",
            height: "40px",
            marginTop: "35px",
          }}
        />

        <Box
          mt={5}
          textAlign="center"
          color="text.main"
          width={{ md: "65%", xs: "100%" }}
        >
          <Button
            sx={{
              background: "#2887C5",
              color: "text.secondary",
              fontSize: { md: "25px", xs: "18px" },
              padding: "12px 28px 12px 28px",
              textTransform: "capitalize",
              fontWeight: "700",
              borderRadius: "15px",
              fontFamily: "Inter",
              "&:hover": {
                background: "#2887C5a1",
              },
            }}
            onClick={HandleAdd}
          >
            Add User
          </Button>
        </Box>

        <Box
          mt={3}
          p={1.5}
          backgroundColor="#F5F5F7"
          sx={{
            width: matches ? "550px" : "300px",
            borderRadius: "15px",
          }}
        >
          <Box
            mt={3}
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "32px", xs: "20px" },
              fontWeight: "bold",
            }}
          >
            Delete User
          </Box>
        </Box>

        <input
          onChange={(e) => setDeleteUser(e.target.value)}
          value={deleteUser}
          type="text"
          style={{
            width: matches ? "60%" : "100%",
            background: "white",
            color: "black",
            boxShadow: "2px 2px grey",
            borderRadius: "5px",
            height: "40px",
            marginTop: "35px",
          }}
        />

        <Box
          mt={5}
          textAlign="center"
          color="text.main"
          width={{ md: "65%", xs: "100%" }}
        >
          <Button
            sx={{
              background: "#2887C5",
              color: "text.secondary",
              fontSize: { md: "25px", xs: "18px" },
              padding: "12px 28px 12px 28px",
              textTransform: "capitalize",
              fontWeight: "700",
              borderRadius: "15px",
              fontFamily: "Inter",
              "&:hover": {
                background: "#2887C5a1",
              },
            }}
            onClick={HandleDelete}
          >
            Delete User
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
