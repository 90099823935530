import { Box, Container, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
// import Stepper from "../stepper/stepperMain";
import { useAccount, useSigner } from "wagmi";
import { useLocation, useSearchParams } from "react-router-dom";
import { ToastNotify } from "../../ToastNotify";
import { MLMContract } from "../../ConnectivityAss/hooks";
import axios from "axios";
import { url, gasEstimationForAll } from "../../utils";
import Loading from "../../loading";

function UpdateMain() {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  let location = useLocation();
  let [params, setParams] = useSearchParams();
  const [updateUserData, setupdateUserData] = useState();
  const [newUser, setnewUser] = useState();
  const [actualUserdata, setactualUserdata] = useState();
  const [loading, setloading] = useState(false);
  const mlmContract = MLMContract(signer);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const matches = useMediaQuery("(min-width : 630px)");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("username") && searchParams.has("wallet_address")) {
      let arr = [];
      const username = searchParams.get("username");
      const wallet_Address = searchParams.get("wallet_address");
      console.log(username);
      if (wallet_Address.length === 42) {
        const obj = {
          username,
          wallet_Address,
        };
        arr.push(obj);
        console.log(obj);
        localStorage.setItem("userParams", JSON.stringify(obj));
        setParams({ key: "dh46hsm41edcfgv6tyh765789ihge4567u" });
        setupdateUserData([...arr]);
      } else {
        setAlertState({
          open: true,
          message: "Wallet address is not correct",
          severity: "error",
        });
      }
    }
  }, [location]);
  useEffect(() => {
    const actualData = JSON.parse(localStorage.getItem("userParams"));
    setactualUserdata(actualData);
  }, [updateUserData]);

  const HandleUpdate = async () => {
    if (actualUserdata?.length === 0) {
      setAlertState({
        open: true,
        message: `Please check username and old wallet address`,
        severity: "error",
      });
    } else if (!newUser) {
      setAlertState({
        open: true,
        message: `Please check new wallet address`,
        severity: "error",
      });
    } else if (actualUserdata?.wallet_Address !== address) {
      setAlertState({
        open: true,
        message: `Please connect your wallet to ${actualUserdata.wallet_Address}`,
        severity: "error",
      });
    } else {
      try {
        setloading(true);
        let params = [
          actualUserdata.username,
          // actualUserdata.wallet_Address,
          newUser,
        ];

        let fn = mlmContract.estimateGas.updateRegisterData2;
        const tx = await mlmContract.updateRegisterData2(...params, {
          gasLimit: gasEstimationForAll(address, fn, params),
        });
        await tx.wait();
        let inputs = {
          username: actualUserdata.username,
          old_wallet: actualUserdata.wallet_Address,
          new_wallet: newUser,
        };
        let { data } = await axios.post("/updateUser", inputs);
        if (data?.codice === "OK") {
          setAlertState({
            open: true,
            message: data.descrizione,
            severity: "info",
          });
          window.location.replace("https://sea.datexit.com/miei_piani.php");
        } else {
          setAlertState({
            open: true,
            message: data.descrizione,
            severity: "error",
          });
        }
        setloading(false);
      } catch (error) {
        console.log(error);
        if (error?.data?.message) {
          setAlertState({
            open: true,
            message: error?.data?.message,
            severity: "error",
          });
        } else if (error?.reason) {
          setAlertState({
            open: true,
            message: error?.reason,
            severity: "error",
          });
        } else {
          setAlertState({
            open: true,
            message: error?.message,
            severity: "error",
          });
        }
        setloading(false);
      }
    }
  };

  return (
    <Box py={2}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />

      <Container>
        <Box>
          <img src="images/seaLogo.png" width="130px" alt="" />
        </Box>
        <Box pt={8}>
          <Container maxWidth="md">
            <Box
              bgcolor="#EDF1FE"
              py={4}
              px={matches ? 6 : 4}
              borderRadius="5px"
              boxShadow={3}
            >
              <Box
                textAlign="center"
                fontSize={{ md: "30px", xs: "22px" }}
                fontFamily="Inter"
                fontWeight="500"
                color="black"
              >
                Update Record
              </Box>
              <Box mt={4}>
                <Box color="black" fontFamily="Inter" mb={1}>
                  Username
                </Box>

                <input
                  placeholder="John Doe"
                  type="text"
                  style={{
                    width: "85%",
                    height: "38px",
                    border: "1px solid #E5E5E5",
                    paddingLeft: "15px",
                    fontSize: matches ? "20px" : "25px",
                    fontWeight: 700,
                    color: "#727272",
                  }}
                  value={actualUserdata?.username}
                  readOnly
                />
              </Box>
              <Box mt={1}>
                <Box color="black" fontFamily="Inter" mb={1}>
                  Old Wallet Address
                </Box>
                <input
                  placeholder="0x567890ds98f675eda70990s8dfa0"
                  type="text"
                  style={{
                    width: "85%",
                    height: "38px",
                    border: "1px solid #E5E5E5",
                    paddingLeft: "15px",
                    fontSize: matches ? "20px" : "25px",
                    fontWeight: 700,
                    color: "#727272",
                  }}
                  readOnly
                  value={actualUserdata?.wallet_Address}
                />
              </Box>
              <Box mt={1}>
                <Box color="black" fontFamily="Inter" mb={1}>
                  New Wallet Address
                </Box>
                <input
                  placeholder="New wallet address"
                  type="text"
                  style={{
                    width: "85%",
                    height: "38px",
                    border: "1px solid #E5E5E5",
                    paddingLeft: "15px",
                    fontSize: matches ? "20px" : "25px",
                    fontWeight: 700,
                    color: "#727272",
                  }}
                  onChange={(e) => setnewUser(e.target.value)}
                  value={newUser}
                />
              </Box>
              <Box mt={5} textAlign="center">
                <button
                  disabled={!newUser}
                  type="submit"
                  style={{
                    cursor: !newUser ? "not-allowed" : "pointer",
                    background: "#0071BD",
                    fontFamily: "Inter",
                    fontSize: matches ? "20px" : "25px",
                    fontWeight: "700",
                    color: "white",
                    width: "90%",
                    height: "45px",
                    border: "none",
                    "&:hover": {
                      background: "#0071BDa1",
                    },
                  }}
                  onClick={() => HandleUpdate()}
                >
                  Update
                </button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Container>
    </Box>
  );
}

export default UpdateMain;
