import { Box, Container, Button, useMediaQuery } from "@mui/material";
import { useState, useContext } from "react";
import { MLMContract } from "../../ConnectivityAss/hooks";
import { AppContext, gasEstimationForAll } from "../../utils";
import { ToastNotify } from "../../ToastNotify";
import Loading from "../../loading";
import { useAccount, useSigner } from "wagmi";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const matches = useMediaQuery("(min-width : 630px)");
  const [user, setUser] = useState();
  const [reward, setReward] = useState();
  const [company, setCompany] = useState();
  const { address } = useAccount();
  const { data: signer } = useSigner();
  // const { signer, account } = useContext(AppContext);
  const mlmContract = MLMContract(signer);
  const [loading, setloading] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const HandleCompanyWallet = async () => {
    try {
      if (!company) {
        setAlertState({
          open: true,
          message: `Please add address to change company wallet.`,
          severity: "error",
        });
      } else {
        setloading(true);
        let data = [company];
        let fn = mlmContract.estimateGas.changeCompanyWallet;
        const tx = await mlmContract.changeCompanyWallet(...data, {
          gasLimit: gasEstimationForAll(address, fn, data),
        });
        await tx.wait();
        setAlertState({
          open: true,
          message: `Company wallet updated.`,
          severity: "success",
        });
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  const HandlePaymentWallet = async () => {
    try {
      if (!user) {
        setAlertState({
          open: true,
          message: `Please add address to change payment wallet.`,
          severity: "error",
        });
      } else {
        setloading(true);
        let data = [user];
        let fn = mlmContract.estimateGas.changeBOT_A;
        const tx = await mlmContract.changeBOT_A(...data, {
          gasLimit: gasEstimationForAll(address, fn, data),
        });
        await tx.wait();
        setAlertState({
          open: true,
          message: `User payment wallet updated.`,
          severity: "success",
        });
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  const HandleRewardWallet = async () => {
    try {
      if (!reward) {
        setAlertState({
          open: true,
          message: `Please add address to change reward wallet.`,
          severity: "error",
        });
      } else {
        setloading(true);
        let data = [reward];
        let fn = mlmContract.estimateGas.changeBOT_B;
        const tx = await mlmContract.changeBOT_B(...data, {
          gasLimit: gasEstimationForAll(address, fn, data),
        });
        await tx.wait();
        setAlertState({
          open: true,
          message: `Reward wallet updated.`,
          severity: "success",
        });
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  return (
    <Box px={3} color="text.main">
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />

      <Container>
        <Box
          mt={3}
          p={1.5}
          backgroundColor="#F5F5F7"
          sx={{
            width: matches ? "550px" : "300px",
            borderRadius: "15px",
          }}
        >
          <Box
            mt={3}
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "32px", xs: "20px" },
              fontWeight: "bold",
            }}
          >
            Wallet A (users payment wallet)
          </Box>
        </Box>

        <input
          onChange={(e) => setUser(e.target.value)}
          value={user}
          type="text"
          style={{
            width: matches ? "60%" : "100%",
            background: "white",
            color: "black",
            boxShadow: "2px 2px grey",
            borderRadius: "5px",
            height: "40px",
            marginTop: "35px",
          }}
        />

        <Box
          mt={5}
          textAlign="center"
          color="text.main"
          width={{ md: "65%", xs: "100%" }}
        >
          <Button
            sx={{
              background: "#2887C5",
              color: "text.secondary",
              fontSize: { md: "25px", xs: "18px" },
              padding: "12px 28px 12px 28px",
              textTransform: "capitalize",
              fontWeight: "700",
              borderRadius: "15px",
              fontFamily: "Inter",
              "&:hover": {
                background: "#2887C5a1",
              },
            }}
            onClick={HandlePaymentWallet}
          >
            Update Wallet
          </Button>
        </Box>

        <Box
          mt={3}
          p={1.5}
          backgroundColor="#F5F5F7"
          sx={{
            width: matches ? "550px" : "300px",
            borderRadius: "15px",
          }}
        >
          <Box
            mt={3}
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "32px", xs: "20px" },
              fontWeight: "bold",
            }}
          >
            Wallet B (rewards wallet)
          </Box>
        </Box>

        <input
          onChange={(e) => setReward(e.target.value)}
          value={reward}
          type="text"
          style={{
            width: matches ? "60%" : "100%",
            background: "white",
            color: "black",
            boxShadow: "2px 2px grey",
            borderRadius: "5px",
            height: "40px",
            marginTop: "35px",
          }}
        />

        <Box
          mt={5}
          textAlign="center"
          color="text.main"
          width={{ md: "65%", xs: "100%" }}
        >
          <Button
            sx={{
              background: "#2887C5",
              color: "text.secondary",
              fontSize: { md: "25px", xs: "18px" },
              padding: "12px 28px 12px 28px",
              textTransform: "capitalize",
              fontWeight: "700",
              borderRadius: "15px",
              fontFamily: "Inter",
              "&:hover": {
                background: "#2887C5a1",
              },
            }}
            onClick={HandleRewardWallet}
          >
            Update Wallet
          </Button>
        </Box>

        <Box
          mt={3}
          p={1.5}
          backgroundColor="#F5F5F7"
          sx={{
            width: matches ? "550px" : "300px",
            borderRadius: "15px",
          }}
        >
          <Box
            mt={3}
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "32px", xs: "20px" },
              fontWeight: "bold",
            }}
          >
            Company wallet
          </Box>
        </Box>

        <input
          onChange={(e) => setCompany(e.target.value)}
          value={company}
          type="text"
          style={{
            width: matches ? "60%" : "100%",
            background: "white",
            color: "black",
            boxShadow: "2px 2px grey",
            borderRadius: "5px",
            height: "40px",
            marginTop: "35px",
          }}
        />

        <Box
          mt={5}
          textAlign="center"
          color="text.main"
          width={{ md: "65%", xs: "100%" }}
        >
          <Button
            sx={{
              background: "#2887C5",
              color: "text.secondary",
              fontSize: { md: "25px", xs: "18px" },
              padding: "12px 28px 12px 28px",
              textTransform: "capitalize",
              fontWeight: "700",
              borderRadius: "15px",
              fontFamily: "Inter",
              "&:hover": {
                background: "#2887C5a1",
              },
            }}
            onClick={HandleCompanyWallet}
          >
            Update Wallet
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
