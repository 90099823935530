/* eslint-disable react-hooks/exhaustive-deps */
import ResponsiveDrawer from "../AdminDrawer/AdminDrawer";
import Distribute from "../distribute/distribute";
import LoadFund from "../loadFunds/loadFund";
import Users from "../users/userPage";
import WalletPage from "../walletPage/walletPage";
import Apis from "../apisPage/apis";
import { Routes, Route, Navigate } from "react-router-dom";
import DistributeTable from "../distribute/distributeTable";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import Main from "../Main";
import { formatUnits } from "@ethersproject/units";
import { MLMTokenContract, MLMContract } from "../../ConnectivityAss/hooks";
import { MLMAddress } from "../../ConnectivityAss/environment";

const Dashboard = ({ setopenModal }) => {
  const { address } = useAccount();
  const [tokenBalance, setTokenBalance] = useState();
  const [Admin, setAdmin] = useState();
  const [botB, setbotB] = useState();

  const TokenContract = MLMTokenContract();
  const Contract = MLMContract();
  const init1 = async () => {
    const admin = await Contract.admin();
    setAdmin(admin);
    const botB = await Contract.BOT_B();
    setbotB(botB);
  };

  const init = async () => {
    const bal = await TokenContract.balanceOf(MLMAddress);
    setTokenBalance(+formatUnits(bal, 6));
  };
  useEffect(() => {
    init();
  }, [address]);
  useEffect(() => {
    init1();
  }, []);

  return (
    <ResponsiveDrawer setopenModal={setopenModal}>
      <Routes>
        <Route
          exact
          path="/"
          element={<Main address={address} Admin={Admin} />}
        />
        <Route
          exact
          path="/distribute"
          element={
            address === Admin ? (
              <Distribute tokenBalance={tokenBalance} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/distribute/next"
          element={<DistributeTable tokenBalance={tokenBalance} />}
        />
        <Route
          path="/loadfunds"
          element={
            address === botB ? <LoadFund botB={botB} /> : <Navigate to="/" />
          }
        />
        <Route
          exact
          path="/users"
          element={address === Admin ? <Users /> : <Navigate to="/" />}
        />
        <Route path="/wallet" element={<WalletPage />} />
        <Route path="/apis" element={<Apis />} />
      </Routes>
    </ResponsiveDrawer>
  );
};
export default Dashboard;
