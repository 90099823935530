import React from "react";
import { Box } from "@mui/material";
import UpdateConnect from "./UpdateConnect";
import UpdateMain from "./UpdateMain";
import { useAccount } from "wagmi";

function UpdateUser({ setopenModal }) {
  const { address } = useAccount();
  return (
    <Box>
      {address ? <UpdateMain /> : <UpdateConnect setopenModal={setopenModal} />}
    </Box>
  );
}

export default UpdateUser;
