import { Box, Container, useMediaQuery, Grid, Skeleton } from "@mui/material";
import circle from "../../images/ellipse.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ tokenBalance }) => {
  const matches = useMediaQuery("(min-width : 900px)");
  const [list, setList] = useState();
  const getList = async () => {
    const { data } = await axios.get("/distributeList");
    setList(data);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box>
      <Container>
        <Grid container>
          <Grid item md={9} xs={12}>
            <Box color="text.main">
              <Box
                mt={3}
                p={1.5}
                backgroundColor="#F5F5F7"
                sx={{
                  width: matches ? "350px" : "100%",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { md: "32px", xs: "20px" },
                    fontWeight: "bold",
                  }}
                >
                  {tokenBalance ? parseFloat(tokenBalance).toFixed(1) : 0.0}{" "}
                  USDT
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { md: "18px", xs: "14px" },
                  }}
                >
                  Current funds
                </Box>
              </Box>
              {list ? (
                list.map((item, index) => {
                  // console.log(item);
                  return (
                    <Link
                      to="/distribute/next"
                      state={{ data: item }}
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        my={5}
                        sx={{
                          background: "#383838",
                          width: matches ? "80%" : "100%",
                          borderRadius: "20px",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          py={2}
                          px={matches ? 7 : 2}
                          sx={{
                            backgroundImage: `url(${circle})`,
                            backgroundRepeat: "no-repeat",
                            backgroundCover: "cover",
                            backgroundPosition: "right",
                            color: "text.secondary",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // flexDirection: { md: 'row', xs: 'column' },
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Box
                                sx={{
                                  fontSize: { md: "18px", xs: "14px" },
                                  fontFamily: "Poppins",
                                }}
                              >
                                Distribution Request
                              </Box>
                              <Box
                                sx={{
                                  fontSize: { md: "14px", xs: "11px" },
                                  fontFamily: "Poppins",
                                }}
                              >
                                {item.date}
                              </Box>
                            </Box>
                            <Box>
                              <ArrowForwardIcon
                                sx={{
                                  background: "white",
                                  color: "black",
                                  fontSize: "2rem",
                                  borderRadius: "5px",
                                  // marginRight :'20px'
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  );
                })
              ) : (
                <Skeleton variant="rectangular" width={210} height={118} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
