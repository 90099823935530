import {
  Box,
  Button,
  Container,
  Grid,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stepper from "../stepper/stepperMain";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import { url, gasEstimationForAll } from "../../utils";
import { ToastNotify } from "../../ToastNotify";
import Loading from "../../loading";
import { MLMContract } from "../../ConnectivityAss/hooks";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { parseUnits } from "@ethersproject/units";
import { useAccount, useSigner } from "wagmi";
import { useEffect } from "react";
import { useState } from "react";

const DistributeTable = ({ tokenBalance }) => {
  const loc = useLocation();
  const main = loc.state?.data;
  const navigate = useNavigate();
  const { address } = useAccount();
  const { data: signer } = useSigner();

  const mlmContract = MLMContract(signer);
  const matches = useMediaQuery("(min-width : 900px)");
  const [open, setOpen] = React.useState(false);
  const [limit, setlimit] = React.useState();
  const [userNum, setuserNum] = React.useState();
  const [record, setRecord] = React.useState();

  const [apply, setApply] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [total, setTotal] = useState();
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });
  useEffect(() => {
    let amountArr = [];
    for (let index = 0; index < record?.length; index++) {
      amountArr.push(record[index].amount);
    }
    const Total = amountArr.reduce((partialSum, a) => +partialSum + a, 0);
    setTotal(Total);
  }, [record]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleDistribute = async () => {
    try {
      let addressArr = [];
      let amountArr = [];
      let id = [];
      for (let index = 0; index < record.length; index++) {
        addressArr.push(record[index].address);
        amountArr.push(
          parseUnits(record[index].amount.toString(), 6).toString()
        );
        id.push(record[index].id);
      }
      setloading(true);

      console.log(addressArr, amountArr);
      console.log(id);

      let dat = [addressArr, amountArr];
      let fn = mlmContract.estimateGas.distribute;
      const tx = await mlmContract.distribute(...dat, {
        gasLimit: gasEstimationForAll(address, fn, dat),
      });
      await tx.wait();
      setAlertState({
        open: true,
        message: `Distributed Successfully`,
        severity: "success",
      });
      for (let i = 0; i < id.length; i++) {
        let body = {
          id: id[i],
          data: moment().format("DD/MM/YYYY"),
        };
        let idd = id[i];
        let { data } = await axios.post(`/updateDistribute?id=${idd}`);
        navigate("/");
        let result = await axios.post("/distributeResponse", body);
        console.log(result?.data?.codice);
        // if(data.codice === 'OK'){
        //   let idd = id[i]
        //   let { data } = await axios.post(
        //     `${url}/updateDistribute?id=${idd}`
        //   );
        //   console.log(data);
        // }
        if (result?.data?.descrizione) {
          setAlertState({
            open: true,
            message: result?.data?.descrizione,
            severity: "info",
          });
        } else {
          setAlertState({
            open: true,
            message: result?.data?.codice,
            severity: "info",
          });
        }
        // let idd = id[i];
        // let { data } = await axios.post(`/updateDistribute?id=${idd}`);
        // navigate("/");
      }

      setloading(false);
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  const handleApply = () => {
    setApply(true);
  };

  return (
    <Box pt={7}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container>
        <Grid container>
          <Grid item md={7} xs={12}>
            <Box>
              <Box
                mt={3}
                px={5}
                py={1}
                backgroundColor="#F5F5F7"
                borderRadius="15px"
                sx={{
                  width: matches ? "350px" : "100%",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { md: "32px", xs: "20px" },
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  {tokenBalance ? parseFloat(tokenBalance).toFixed(1) : 0.0}{" "}
                  USDT
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { md: "18px", xs: "14px" },
                    fontWeight: "700",

                    color: "black",
                  }}
                >
                  Current funds
                </Box>
              </Box>

              <Box
                mt={5}
                sx={{
                  width: matches ? "350px" : "250px",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { md: "30px", xs: "20px" },
                    color: "black",
                    fontWeight: "700",
                  }}
                >
                  Distribution Request
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { md: "18px", xs: "14px" },
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  {main ? main.date : "..."}
                </Box>
              </Box>
            </Box>
          </Grid>
          {apply ? (
            <Grid item md={5} xs={12}>
              <Box mt={15}>
                <TableContainer>
                  <Table
                    sx={{
                      border: "1px solid black",
                      backgroundColor: "#C4C4C4",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            border: "1px solid black",
                            color: "black",
                            fontWeight: "bold",
                            fontFamily: "Inter",
                            // fontSize: { md: "20px", xs: "16px" },
                          }}
                        >
                          User
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            border: "1px solid black",
                            color: "black",
                            fontWeight: "bold",
                            fontFamily: "Inter",
                            fontSize: { md: "20px", xs: "16px" },
                          }}
                        >
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record ? (
                        <>
                          {record.map((data, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  align="center"
                                  sx={{
                                    border: "1px solid black",
                                    padding: "0px",
                                  }}
                                >
                                  <input
                                    value={
                                      data.address.slice(0, 10) +
                                      "...." +
                                      data.address.slice(-6)
                                    }
                                    placeholder="Wallet Address"
                                    style={{
                                      height: "44px",
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      padding: "0px 10px",
                                      textAlign: "center",
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    border: "1px solid black",
                                    padding: "0px",
                                    width: "40%",
                                  }}
                                >
                                  <input
                                    value={data.amount}
                                    placeholder="Amount"
                                    style={{
                                      width: "100%",
                                      height: "44px",
                                      border: "none",
                                      outline: "none",
                                      padding: "0px 10px",
                                      textAlign: "center",
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow key={"abc"}>
                            <TableCell
                              align="center"
                              sx={{
                                border: "1px solid black",
                                padding: "0px",
                              }}
                            >
                              <input
                                value={"Total"}
                                readOnly
                                style={{
                                  height: "44px",
                                  width: "100%",
                                  border: "none",
                                  outline: "none",
                                  padding: "0px 10px",
                                  textAlign: "center",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: "1px solid black",
                                padding: "0px",
                                width: "40%",
                              }}
                            >
                              <input
                                value={total && total}
                                placeholder="Amount"
                                style={{
                                  width: "100%",
                                  height: "44px",
                                  border: "none",
                                  outline: "none",
                                  padding: "0px 10px",
                                  textAlign: "center",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow key={0}>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid black" }}
                            colSpan="2"
                          >
                            <Box>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box textAlign="center" mt={5}>
                <Button
                  onClick={handleDistribute}
                  style={{
                    background: "#2887C5",
                    color: "white",
                    borderRadius: "10px",
                    width: "170px",
                    height: "50px",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    fontFamily: "Inter",

                    fontWeight: "700",
                    "&:hover": {
                      background: "#2887C5a1",
                    },
                  }}
                >
                  Distribute
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid md={4} xs={12}>
              <Box mt={matches ? 0 : 4}>
                <Box
                  sx={{
                    background: "#C9DDE3",
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    pt={5}
                    sx={{
                      fontSize: { md: "30px", xs: "20px" },
                      fontFamily: "Baloo Bhai",
                      color: "text.secondary",
                      textAlign: "center",
                      // fontWeight: "bold",
                    }}
                  >
                    Distribution
                  </Box>

                  <Box mt={7} pb={6} px={4}>
                    {/* <Box display="flex" justifyContent="space-between">
                    <Box color="text.main" sx={{ fontWeight: "700" }}>
                      Automatic
                    </Box>
                    <Switch checked={checked} onChange={handleChange} />
                  </Box> */}

                    <Box
                      mt={5}
                      mb={1}
                      color="text.main"
                      sx={{ fontWeight: "700" }}
                    >
                      Transfer limit
                    </Box>
                    <input
                      onChange={(e) => setlimit(e.target.value)}
                      value={limit}
                      style={{
                        color: "text.main",
                        width: "55%",
                        height: "36px",
                        background: "rgba(255, 255, 255, 0.7)",
                        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                    />
                    <Box
                      mt={3}
                      mb={1}
                      color="text.main"
                      sx={{ fontWeight: "700" }}
                    >
                      Number of users per tx
                    </Box>
                    <input
                      onChange={(e) => setuserNum(e.target.value)}
                      value={userNum}
                      style={{
                        color: "text.main",
                        width: "55%",
                        height: "36px",
                        background: "rgba(255, 255, 255, 0.7)",
                        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      onClick={() => {
                        console.log(limit, "limit");
                        let amount = main.data?.map((data) => data.amount);
                        let value = amount.reduce(
                          (partialSum, a) => partialSum + a,
                          0
                        );
                        if (limit >= value) {
                          if (userNum) {
                            let myRecord = main.data?.slice(0, userNum);
                            setRecord(myRecord);
                          }
                        } else if (limit < value) {
                          let amount = 0;
                          for (let x = 0; x < main.data.length; x++) {
                            amount += main.data[x].amount;
                            console.log(amount);
                            if (amount === limit) {
                              let i = x + 1;
                              let myRecord = main.data?.slice(0, i);
                              setRecord(myRecord);
                              break;
                            } else if (amount > limit) {
                              if (main.data[x].amount <= limit) {
                                let myRecord = [main.data[x]];
                                setRecord(myRecord);
                                break;
                              }
                            }
                          }
                        } else {
                          setAlertState({
                            open: true,
                            message: "Amount is not in this limit",
                            severity: "error",
                          });
                        }
                        handleApply();
                      }}
                      sx={{
                        background: "#2887C5",
                        color: "text.secondary",
                        fontSize: { md: "20px", xs: "14px" },
                        padding: "5px 50px 5px 50px",
                        borderRadius: "10px",
                        fontFamily: "Inter",
                        marginBottom: "25px",
                        "&:hover": {
                          background: "#2887C5a1",
                        },
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
      <Dialog open={open} onClose={handleClose} style={{ width: "100%" }}>
        <Stepper />
      </Dialog>
    </Box>
  );
};
export default DistributeTable;
