import { Box, Container, Button, useMediaQuery, Grid } from "@mui/material";


export default () => {
    return <Box px={3} color='text.main'>
        <Container>
            <Box mt={5} sx={{
                fontFamily: 'Poppins',
                fontSize: { md: '32px', xs: '20px' },
                fontWeight: 'bold'
            }}>
                API end point info
            </Box>


            <Box mt={10} sx={{
                fontFamily: 'Poppins',
                fontSize: { md: '32px', xs: '20px' },
                fontWeight: 'bold'
            }}>
                How to connect to the backend API
            </Box>
            <Box mt={5} sx={{
                fontFamily: 'Poppins',
                fontSize: { md: '32px', xs: '20px' },
                fontWeight: 'bold'
            }}>
                Any security token info
            </Box>
        </Container>
    </Box>
}