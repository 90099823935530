import { Box, Container, Button, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Stepper from "../stepper/stepperMain";
import React from "react";
import { MLMAddress } from "../../ConnectivityAss/environment";
import { MLMTokenContract, MLMContract } from "../../ConnectivityAss/hooks";
import { formatUnits } from "@ethersproject/units";
import { useAccount, useSigner } from "wagmi";

const LoadFund = () => {
  const matches = useMediaQuery("(min-width : 900px)");
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const TokenContract = MLMTokenContract(signer);
  const Contract = MLMContract();
  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = React.useState();
  const [botBalance, setBotBalance] = React.useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const getData = async () => {
    const balance = await TokenContract.balanceOf(MLMAddress);
    setBalance(+formatUnits(balance.toString(), 6));
    const accontBalance = await TokenContract.balanceOf(address);
    const accbalance = +formatUnits(accontBalance.toString(), 6);
    setBotBalance(Number(accbalance).toFixed(1));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box px={3} color="text.main">
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            mt={3}
            p={1.5}
            backgroundColor="#F5F5F7"
            sx={{
              width: matches ? "350px" : "250px",
              borderRadius: "15px",
              // display : 'flex',
            }}
          >
            <Box
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "32px", xs: "20px" },
                fontWeight: "bold",
              }}
            >
              {balance ? balance : "0.0"} USDT
            </Box>
            <Box
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "18px", xs: "14px" },
              }}
            >
              Current funds in the smart contract
            </Box>
          </Box>
          {/* {address ? (
            <Box
              mt={4.5}
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "25px", xs: "18px" },
                fontWeight: "bold",
              }}
            >
              Connected to output wallet
            </Box>
          ) : (
            ""
          )} */}
        </Box>

        <Box
          mt={3}
          p={1.5}
          backgroundColor="#F5F5F7"
          sx={{
            width: matches ? "350px" : "250px",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "32px", xs: "20px" },
              fontWeight: "bold",
            }}
          >
            {botBalance ? botBalance : "0.0"} USDT
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins",
              fontSize: { md: "18px", xs: "14px" },
            }}
          >
            Current funds in Wallet B (output wallet)
          </Box>
        </Box>

        <Box marginTop="20%" textAlign="center" color="text.main">
          <Button
            onClick={handleOpen}
            sx={{
              background: "#2887C5",
              color: "text.secondary",
              fontSize: { md: "25px", xs: "14px" },
              padding: "5px 50px 5px 50px",
              borderRadius: "10px",
              fontFamily: "Inter",
              "&:hover": {
                background: "#2887C5a1",
              },
            }}
          >
            Move funds to Smart contract
          </Button>
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose} style={{ width: "100%" }}>
        <Stepper />
      </Dialog>
    </Box>
  );
};
export default LoadFund;
