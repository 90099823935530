import React, { useState, useEffect } from "react";
import "./App.css";
import { createTheme } from "@mui/material";
import Login from "./components/loginPage/login";
import Dashboard from "./components/Dashboard/dashboard";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { useConnect } from "wagmi";
import NetworkChange from "./networkSwitch";
import Web3 from "web3";
import Wallet from "./Wallet";
import { rpc } from "./utils";
import UpdateUser from "./components/UpdateUser/UpdateUser";

function App() {
  const { connect } = useConnect();
  const [openModal, setopenModal] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      text: {
        main: "#121212",
        secondary: "#fff",
      },
    },
  });
  const [switchNetwork, setswitchNetwork] = useState(false);
  const web3 = new Web3(Web3.givenProvider ? Web3.givenProvider : rpc);
  let chain = async () => {
    try {
      if (window.web3) {
        const chainid = await web3.eth.getChainId();
        if (+chainid !== 1) {
          setswitchNetwork(true);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    const storage = localStorage.getItem("wallet");
    chain();
    if (storage === "meta") {
      connect();
    }
  }, []);
  return (
    <>
      <Wallet setOpen={setopenModal} open={openModal} />
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={<Dashboard setopenModal={setopenModal} />}
          />
          <Route
            path="/updateUser"
            element={<UpdateUser setopenModal={setopenModal} />}
          />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
