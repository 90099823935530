import React from "react";
import { Contract } from "@ethersproject/contracts";
import MLMAbi from "./MLMAbi.json";
import MLMTokenAbi from "./MLMTokenAbi.json";
import { MLMAddress, MLMTokenAddress } from "./environment";
import { ethers } from "ethers";
let walletAddress = "0x31478348f50F0bB9c16b98dEf01824f4D6a45940";

export const provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
);

export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function MLMContract(signer) {
  return useContract(MLMAddress, MLMAbi, signer);
}
export function MLMTokenContract(signer) {
  return useContract(MLMTokenAddress, MLMTokenAbi, signer);
}
